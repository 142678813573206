@import '../../../_variables.module.scss';

.footerContainer {

    background:  linear-gradient(0deg, #3C6189 -10%, transparent 80%);
    width: 100vw;
    height: 20vh;
    //margin-top: -30vh;
    margin-right: 0;
    margin-left: 0;
    align-items: flex-end;

    span {
        padding: 2vh 5vw;
        font-size: 1rem;
        color: white;
        text-align: center;

        a {
            text-decoration: none;
            color: white;
            transition: color 400ms;
        }

        a:hover {
            color: $HIGHLIGHT_ORANGE;
        }
    }
}