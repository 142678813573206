@import '../../../_variables.module.scss';

.backContainer {
    opacity: 0;
    height: 100%;
    transition: opacity 0.7s ease-in-out;
    width: 100vw;
    display: grid;
    background: $SHAPES_BACKGROUND_COLOR;
    margin: auto 0;

    img {
        padding: 0;
        align-self: center;
    }
}

.visible {
    opacity: 1;
}

.contentWrapper, .imgBack{
    grid-column: 1;
    grid-row: 1;
}
  

.contentWrapper {
    height: 100%;
    z-index: 2;
    margin: auto 0;
}

.imgBack {
    position: absolute;
    left: 0;
    top: 4vh;
    width: 100%;
    z-index: 0;
    justify-self: center;
}

@media screen and (min-width: $BOOT_LG) {
    .backContainer {
        height: auto;
        background: transparent !important;
    }
}