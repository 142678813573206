$MAIN_BLUE: #1D497A;
$MAIN_GREY: #BEBEBE;
$HIGHLIGHT_BLUE: #4FB3E4;
$HIGHLIGHT_ORANGE: #FCA311;

$MAX_MAIN_WIDTH: 1800px;

$BOOT_SM: 576px;
$BOOT_MD: 768px;
$BOOT_LG: 992px;

$PADDING_XS: 10px;
$PADDING_SM: 20px;
$PADDING_MD: 40px;
$PADDING_LG: 80px;
$PADDING_XL: 160px;

$PADDING_CONTAINER: 2vh 5vw;

$SHAPES_BACKGROUND_COLOR: linear-gradient(281.76deg, #8FBEF3 3.71%, #3A5F88 87.33%);

@font-face {
    font-family: 'Poppins-ExtraBold';
    src: local('sans-serif'), url('./fonts/Poppins-ExtraBold.woff2') format('woff2');
    font-display: fallback;
}

@font-face {
    font-family: 'Poppins-Medium';
    src: local('sans-serif'), url('./fonts/Poppins-Medium.ttf') format('truetype');
    font-display: fallback;
}

@font-face {
    font-family: 'Poppins-Thin';
    src: local('sans-serif'), url('./fonts/Poppins-Thin.ttf') format('truetype');
    font-display: fallback;
}