@import '../../../_variables.module.scss';

.vertical-timeline.vertical-timeline--two-columns:before {
    z-index: -1;
}

.show-more-cert-container {
    width: 100%;
    display:  flex;
    background-color: white;
    padding: 15px;
}

.show-more-cert-container {
    font-size: 1.5rem;
    color: $MAIN_BLUE;
    transition: color 400ms;
    font-weight: bold;
    cursor: pointer;
}

.show-more-cert-container:hover {
    color: $HIGHLIGHT_BLUE;
}

.vertical-timeline-element-content { 
    box-shadow: none !important;
    vertical-timeline-element-content: none !important;
    border: 1px solid #ddd;
}

.vertical-timeline-element-content-arrow {
    border: 7px solid white !important;
    border-right: 7px solid #ddd !important;
}

.anchor-t {
    color: $MAIN_BLUE;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    text-align: left;
    background: none;
    border: none;
    padding: 16px 0 0 0;
}

@media screen and (max-width: 1170px) {
    .anchor-t {
        padding: 10px 0 0 16px;
    }
}

.anchor-t:hover {
    color: $HIGHLIGHT_BLUE;
}
