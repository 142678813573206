@import '../_variables.module.scss';
@import 'bootstrap/dist/css/bootstrap.min.css';

.main {
    width: 100vw;
    position: relative;
    top: 0;
    // apply left positioning only when main is smaller than actual vw
    /*@media screen and (min-width: #{$MAX_MAIN_WIDTH}) {
        left: calc((100vw - #{$MAX_MAIN_WIDTH}) / 2);
    }*/
    overflow-x: hidden;
    //padding-top: 10vmax;
    min-height: 100vh;
    //max-width: $MAX_MAIN_WIDTH;
}

* {
    font-family: 'Poppins-Medium' sans-serif;
}

h2 {
    font-family: 'Poppins-ExtraBold' sans-serif;
}

.sectionTitles {
    font-size: 3rem;
    font-family: 'Poppins-ExtraBold' sans-serif;
    color: $MAIN_BLUE;
    font-weight: bold;
    letter-spacing: 3px;
    text-transform: uppercase;
    //margin: auto 5%;
}

@media screen and (min-width: $BOOT_LG) {
    .sectionTitles {
        font-size: 4rem;
    }
}