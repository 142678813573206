@import '../../../_variables.module.scss';

.contact-text-container {
    text-align: center;
    padding: 4vh 0;

    h2 {
        color: $MAIN_BLUE;
        padding: 0 10%;
        font-size: 1.25rem;
        font-family: 'Poppins-Medium';

        span {
            font-family: 'Poppins-ExtraBold';

            a {
                text-decoration: none;
                white-space: nowrap;
            }
        }
    }
}

.map-container {
    iframe {
        width: 100%;
        height: 55vh;
        border-radius: 10px;
    }
}