@import '../../../_variables.module.scss';

.articleH2 {
    color: $MAIN_BLUE;
    text-transform: uppercase;
}

.articleHr {
    color: $MAIN_BLUE;
}

.articleImg {
    width: 100%;
    object-fit: contain;
    padding: 20px
}