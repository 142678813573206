@import '../../../_variables.module.scss';

.navBarContainer {
    width: 100vw;
    position: fixed;
    top: 0;
    background-color: white;
    -webkit-box-shadow: 0px 10px 28px 10px rgba(0,0,0,0.125); 
    box-shadow: 0px 10px 28px 10px rgba(0,0,0,0.125);
    margin-right: 0;
    margin-left: 0;
    left: 0;
    right: 0;

    padding: 2vh 0;
    z-index: 999;

    .logoContainer {
        height: 40px;

        @media screen and (min-width: $BOOT_MD) {
            height: 50px;
        }

        .logoWrapper {
            height: 100%;
            
            img {
                height: 100%;
                width: auto;
                padding: 0 2.5vmax;
            }
        }
    }

    .navButton {
        width: min-content;

        div {
            background-color: $MAIN_BLUE;
            width: 30px;
            height: 2px;
            margin: 7px 0;
            transition: transform .5s;
        }

        div:hover {
            background-color: $HIGHLIGHT_BLUE;
        }
    }

    .navButton:hover {
        div {
            background-color: $HIGHLIGHT_BLUE;
        }
    }

    .navButtonActive {
        @extend .navButton;

        div:nth-child(1) {
            transform: translateY(9px) scaleX(1.41) rotate(45deg);
        }

        div:nth-child(3) {
            transform: translateY(-9px) scaleX(1.41) rotate(-45deg);
        }

        div:nth-child(2) {
            transform: translateY(20px);
        }
    }

    .navContainer {
        margin: 20px 0;
        transition: height 0.4s ease-in-out;

        @media screen and (min-width: $BOOT_MD) {
            margin: 0;
            margin-right: 60px;
        }

        .navWrapper {
            align-content: center;
            justify-content: space-evenly;
            height: 100%;

            @media screen and (min-width: $BOOT_MD) {
                margin-right: 30px;
            }

            .navLink {
                width: auto;
                text-align: center;
                text-decoration: none;
                text-transform: capitalize;
                color: $MAIN_BLUE;
                font-size: 1.2rem;
                font-weight: 700;
                padding-bottom: 10px;

                @media screen and (min-width: $BOOT_MD) {
                    padding-bottom: 0;
                    padding: 0 0.75vmax;
                }
                
                transition: color 400ms;
            }

            .navLink:hover {
                color: $HIGHLIGHT_BLUE;
            }
        }
    }

    .navActive {
        height: 300px;
        animation: slideFromTop 0.5s ease-out;

        @media screen and (min-width: $BOOT_MD) {
            height: 100%;
            margin: 20px 0;
        }
    }

    .navInactive {
        height: 0;
        overflow-y: hidden;
        margin: 0;
        transform: translateY(-300px);
        animation: slideToTop 0.5s ease-out;

        @media screen and (min-width: $BOOT_MD) {
            height: 100%;
            margin: 20px 0;
            transform: none;
            animation: none;
        }
    }
}

@keyframes slideFromTop {
    from {
        transform: translateY(-300px);
    }
    to {
        transform: none;
    }
}

@keyframes slideToTop {
    from {
        transform: none;
    }
    to {
        transform: translateY(-300px);
    }
}