.galleryContainer {
    opacity: 0;
    margin-top: 30vh;
    margin-bottom: 15vmin;
    animation: fadeIn 0.8s ease-in-out forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}