@import '../../_variables.module.scss';

.anchor {
    margin-top: 40px;
    margin-bottom: 40px;
    color: $MAIN_GREY;
    font-size: 24px;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
}

.anchor:hover {
    color: $MAIN_BLUE;
}