@import '../../../_variables.module.scss';

.cardWrapper {
    margin-top: $PADDING_MD;
    margin-bottom: $PADDING_MD;

    width: 100%;
    height: auto;
}

@media screen and (min-width: $BOOT_LG) {
    .cardWrapper {
        width: 55%;
    }
}

.icon {
    width: 100px;
    padding-right: $PADDING_MD;
}

.anchor {
    margin-top: 40px;
    margin-bottom: 40px;
    color: $MAIN_GREY;
    font-size: 24px;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
}

.anchor:hover {
    color: $MAIN_BLUE;
}

.secondaryAnchor {
    font-size: 20px;
    color: white;
    font-weight: bold;
    text-decoration: none;
}

.secondaryAnchor:hover {
    color: $MAIN_BLUE;
}