@import '../../_variables.module.scss';

.countersContainer {
    margin-top: 20vmin;
}

.landingContainer {
    margin-top: 8vh;
    height: 92vh;

    .img-silvio {
        width: 75%;
        margin-left: 15%;
        opacity: 0;
        transform: translateY(300px);
        animation: inFromBottom 0.8s ease-in-out 0.2s forwards;
    }

    .landing-title-wrapper {
        color: white;
        text-transform: uppercase;
        z-index: 1;

        h1 {
            font-size: 5rem;
        }

        h2 {
            font-size: 1.5rem;
        }
    }
}

.main-link {
    text-decoration: none;
    width: auto;
    border-radius: 10px;
    font-size: 4vmin;
    color: white;
    background-color: transparent;
    padding: 0.50rem 4rem;
    transition: color 400ms, background-color 400ms;
    border: 2px solid white;
}

.main-link:hover {
    color: $MAIN_BLUE;
    background-color: white;
}

@keyframes inFromBottom {
    from {
        opacity: 0;
        transform: translateY(300px);
    }
    to {
        opacity: 1;
        transform: none;
    }
}

@media screen and (min-width: $BOOT_MD) {
    .landingContainer {
        margin-top: 10vmin;
        height: 98vh;
    }

    .landing-title-wrapper {
        h1 {
            font-size: 6rem !important;
        }

        h2 {
            font-size: 2rem !important;
        }
    }

    .main-link {    
        font-size: 2vmin;
    }
}

@media screen and (min-width: $BOOT_LG) {
    .landingContainer {
        margin-top: 12vmin;
        height: auto;
    }    

    .landing-title-wrapper {
        h1 {
            font-size: 7vmax !important;
        }

        h2 {
            font-size: 4vmin !important;
        }
    }

    .main-link {    
        font-size: 2vmin;
    }
}