@import '../../../_variables.module.scss';

.formContainer {
    background: linear-gradient(281.76deg, #8FBEF3 3.71%, #3A5F88 87.33%);
    
    display: flex;
    justify-content: center;
    margin: 2vmax 2vmax;

    @media screen and (min-width: $BOOT_SM) {
        margin: 3vmax 3vmax;
    }

    border-radius: 10px;

    .formWrapper {
        padding: 5vh 0;

        .checkbox {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 3vh 6vh;

            input[type=checkbox] {
                margin-right: 20px;
                height: 1.5rem;
                width: 1.5rem;
                outline: none;
                border: 2px solid transparent;
                border-radius: 15px;

                &:hover {
                    outline: none;
                    border: 2px solid transparent;
                }
            }

            > span {
                color: white;
                font-size: 1rem;
                width: 70%;
            }
        }

        .fieldWrapper {
            display: flex;
            justify-content: center;

            input {
                font-size: 1.25rem;
                width: 100%;
                border-radius: 10px;
                padding: 6px 10px;
                outline: none;
                border: 2px solid transparent;
            }

            input[type=text] {
                margin: 3vh 3vh;
            }


            input[type=email] {
                margin: 3vh 3vh;
            }

            input[type=text-input] {
                height: 15vh;
                margin: 3vh 3vh;
            }

            button {
                width: 40%;
                border-radius: 10px;
                font-size: 1.5rem;
                color: white;
                background-color: $MAIN_BLUE;
                border: 1px solid transparent;
                padding: 0.25rem 0;
                transition: color 400ms, background-color 400ms;
                border: 1px solid white;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    height: 2.3rem;
                }
            }

            button:hover {
                color: $MAIN_BLUE;
                background-color: white;
            }

            button:disabled {
                background-color: $MAIN_GREY;
                color: white;
                border: 1px solid $MAIN_GREY;
            }
        }

        .feedbackMessage {
            color: white;
            font-size: 1.5rem;
            font-family: 'Poppins-ExtraBold' 'sans-serif';
            opacity: 0;
            transition: opacity 0.5s ease-in-out;
        }

        .visible {
            opacity: 1;
        }
    }
}

.secondaryAnchor {
    color: white;
    font-weight: bold;
    text-decoration: none;
}

.secondaryAnchor:hover {
    color: $MAIN_BLUE;
}

@media screen and (min-width: $BOOT_LG) {
    input[type=text] {
        margin: 3vh 1.5vh 3vh 4vh !important;
    }

    input[type=email] {
        margin: 3vh 4vh 3vh 1.5vh !important;
    }

    input[type=text-input] {
        height: 15vh;
        margin: 3vh 4vh !important;
    }
}