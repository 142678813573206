@import '../../_variables.module.scss';

.chiSonoSectionContainer {
    height: 100vh;
    margin-top: 8vh;
    background-color: white;

    .img-silvio {
        width: 70%;
        margin-left: 15%;
        opacity: 0;
        transform: translateX(300px);
        animation: inFromRight 0.8s ease-in-out 0.2s forwards;
    }

    .title-wrapper {
        color: white;
        text-transform: uppercase;
        z-index: 1;

        h2 {
            font-size: 5vmax;
        }

        p {
            font-size: 2.5vmax;
            padding: 1rem 10%;
            text-transform: none !important;
        }
    }
}

@keyframes inFromRight {
    from {
        opacity: 0;
        transform: translateX(300px);
    }
    to {
        opacity: 1;
        transform: none;
    }
}

@media screen and (min-width: $BOOT_MD) {
    .chiSonoSectionContainer {
        margin-top: 10vmin !important;
        height: 100vh;
    }
}

@media screen and (min-width: $BOOT_LG) {
    .chiSonoSectionContainer {
        margin-top: 12vmin !important;
        height: auto;
    }    

    .title-wrapper {
        h2 {
            margin-top: 2vmax;
            font-size: 3vmax !important;
        }

        p {
            font-size: 1.25vmax !important;
            padding: 1.5vmin 10% !important;
        }
    }
}
