@import '../../../_variables.module.scss';

.counterWrapper {
    .countNumber {
        font-size: 3.25rem;
        color: $MAIN_BLUE;
    }

    .countText {
        font-size: 1.25rem;
        color: $MAIN_GREY;
        padding: 0.5rem;
    }

    .countIco {
        font-size: 2rem;
        color: $MAIN_BLUE;
        margin: 0.5rem;
    }
}

@media screen and (min-width: $BOOT_LG) {
    .countNumber {
        font-size: 6rem !important;
    }

    .countText {
        font-size: 1.5rem !important;
    }

    .countIco {
        font-size: 4rem !important;
        margin: 1rem !important;
    }
}